
declare const hbspt: any;

import { CurrentUser } from '@/types';
import { defineComponent } from 'vue';

export default defineComponent({
    data() {
        return {
            resizeObserver: new ResizeObserver(([entry]: ResizeObserverEntry[]) => {
                const embeddedFormHeight = `${entry.target.ownerDocument.body.scrollHeight + 10}px`;
                parent.postMessage({ embeddedFormHeight }, location.origin);
            }),

            tearDown: null as (() => void) | null,
        };
    },

    computed: {
        provider() {
            return this.$route.query.provider ?? '';
        },
    },

    watch: {
        provider: {
            immediate: true,
            handler(provider) {
                this.tearDown?.();
                if (provider === 'hubspot') {
                    this.setUpHubspot();
                } else if (provider === 'typeform') {
                    this.setUpTypeform();
                }
            },
        },
    },

    mounted() {
        document.documentElement.classList.add('for-embedded-form');
        document.body.classList.add('for-embedded-form');
    },

    beforeDestroy() {
        this.resizeObserver.disconnect();
    },

    methods: {
        setUpHubspot() {
            const HUBSPOT_EMBED_SCRIPT = 'https://js.hsforms.net/forms/embed/v2.js';

            let frame: Element | null = null;

            const handleScriptLoad = () => {
                const { region, portalId, formId } = this.$route.query;

                hbspt.forms.create({
                    region,
                    portalId,
                    formId,
                    onFormReady: (form: HTMLFormElement) => {
                        try {
                            const currentUser = this.$store.state.account.currentUser as CurrentUser | null;
                            if (currentUser) this.autoCompleteForm(form, currentUser);
                        } catch (error) {
                            // Okay
                        }

                        frame = form.ownerDocument.defaultView?.frameElement ?? null;
                        if (frame) {
                            this.resizeObserver.observe(frame);
                        }

                        form.ownerDocument.head.insertAdjacentHTML('beforeend', `
                            <style>
                                .inputs-list > li {
                                    margin-block-end: 1.2em;
                                }
                            </style>
                        `);
                    },
                });
            };

            const script = document.createElement('script');
            script.addEventListener('load', handleScriptLoad);
            script.src = HUBSPOT_EMBED_SCRIPT;
            document.body.append(script);

            this.tearDown = () => {
                script.removeEventListener('load', handleScriptLoad);
                script.remove();
                if (frame) {
                    this.resizeObserver.unobserve(frame);
                    frame.remove(); // There doesn't seem to be a way to destroy the HubSpot form instance.
                }
            };
        },

        setUpTypeform() {
            console.log('SETUP');
            const TYPEFORM_EMBED_SCRIPT = 'https://embed.typeform.com/next/embed.js';

            const { tfLive } = this.$route.query;
            const div = document.createElement('div');
            div.dataset.tfLive = String(tfLive);
            this.resizeObserver.observe(div);
            console.log(this.resizeObserver, 'Observing', div);
            document.body.append(div);

            const script = document.createElement('script');
            script.src = TYPEFORM_EMBED_SCRIPT;
            document.body.append(script);

            this.tearDown = () => {
                script.remove();
                this.resizeObserver.unobserve(div);
                div.remove(); // Likewise, there's no way to clean this thing up.
            };
        },

        autoCompleteForm(form: HTMLFormElement, user: CurrentUser) {
            // For fields, see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
            const autocompleteData = {
                'name': `${user.firstName} ${user.lastName}`,
                'given-name': user.firstName,
                'family-name': user.lastName,
                'email': user.email,
                'tel': user.mobilePhone,
                'address-line1': user.addressComponents.text?.split(',')[0],
                'address-level1': user.addressComponents.state,
                'address-level2': user.addressComponents.city ?? user.addressComponents.place,
                'postal-code': user.addressComponents.zipcode,
            };

            Object.entries(autocompleteData).forEach(([key, value]) => {
                try {
                    const input = form.querySelector(`[autocomplete=${key}]`);
                    if (input && 'value' in input && typeof input.value === 'string') {
                        input.value = value;
                    }
                } catch (error) {
                    // Okay
                }
            });
        },
    },
});
