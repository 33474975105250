
import { defineComponent } from 'vue';
import { doOauth } from '.';

export default defineComponent({
    data() {
        return {
            continueHref: null as string | null,
            error: null as unknown,
        };
    },

    async mounted() {
        try {
            await doOauth();
            this.continueHref = '/';
        } catch (error) {
            this.error = error;
        }
    },
});
